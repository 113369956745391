#dashboard {
	.content {
		padding-bottom: 0;
		padding-top: 20px;
	}
	.dashboard-settings {
		margin-left: 5px;
		color: rgba(0,0,0,.35);
		cursor: pointer;
		&:hover {
			color: #333;
		}
	}
	#dashboard-settings {
		.dashElements {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				font-size: 14px;
				text-transform: capitalize;
			}
		}
	}

	.dashboardContainer {
		column-count: 2;
		column-gap: 0;
		height: auto;
		@media (max-width: 992px){
			column-count: 1;
		}
		.dashboardElement {
			display: inline-block;
			width: 100%;
			height: auto;
			break-inside: avoid;
			@keyframes blink {
				0% {
					border-color: rgba(0,0,0,.15);
				}
				50% {
					border-color: rgba(0,0,0,.25);
				}
				100% {
					border-color: rgba(0,0,0,.15);
				}
			}
			.sortable {
				display: block;
				position: relative;
				margin-top: -5px;
				margin-left: -5px;
				border: 2px dashed rgba(0,0,0,.25);
				animation: blink 2s infinite;
			}
			&.ui-sortable-helper {
				opacity: 1 !important;
				border-top: none !important;
				.pmd-card {
					box-shadow: 0 0 15px rgba(0,0,0,.5) !important;
				}
			}
			p {
				padding: 15px 0 0 15px;
			}
		}
	}
	.ui-placeholder {
		display: block;
		position: relative;
		z-index: 99999;
		height: 25px;
		margin-bottom: 10px;
	}

	.list-group-item {
		h3 {
			i {
				padding-top: 2px;
			}
		}
	}

	.user-info-table {
		width: 100%;
		td {
			padding: .5em;
			&:first-child {
				width: 3em;
				text-align: center;
			}
			&:last-child {
				text-align: left;
			}
		}
	}
	.send-message {
		position: absolute;
		right: 120px;
		@media (max-width: 480px){
			right: 60px;
		}
		margin-top: -54px;
		border: none;
		background-color: transparent;
		i {
			padding-top: 0;
		}
		&:hover {
			color: #e08e0b;
		}
	}
	#messages-list {
		.message-delete {
			position: absolute;
			right: 20px;
			margin-top: -40px !important;
			min-width: 0 !important;
			padding: 3px 0px 4px 4px;
		}
		.message-read {
			margin-right: 25px;
		}
		.list-group-item {
			span, strong {
				color: #4CAF50 !important;
			}
			&[aria-expanded="true"] {
				span, strong {
					color: #fff !important;
				}
			}
			.no-messages {
				color: #333 !important;
			}
		}
	}
	.list-group-item.sortable-handle-container {
		//padding: 0;
		//.ui-sortable-handle {
			//padding: 16px 15px 18px;
		//}
	}

	.element-connected-companies {
		.pull-right {
			padding-top: 5px;
		}
	}

	@media (max-width: 768px){
		.element-connected-companies {
			.table-icon, .table-icon-generated {
				display: none;
			}
		}
	}
	#confirmed-payments-list, #pending-payments-list {
		.list-group-item {
			cursor: pointer;
		}
		p.list-group-item {
			padding-bottom: 10px;
			line-height: 175%;
			cursor: pointer;
		}
	}
}

.globalNotification {
	.pmd-alert {
		background-color: #4CAF50 !important;
	}
	p {
		display: inline-block;
		width: 85%;
		color: #fff;
		.header {
			display: block;
			font-size: 16px;
			font-weight: bold;
		}
	}
	.close {
		opacity: 1;
		float: right;
		margin-right: -20px;
		color: #fff;
	}
}