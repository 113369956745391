.user-delete {
	position: absolute !important;
	margin-top: -50px;
	right: 20px;
}

.user-icon {
	display: inline-block;
	width: 48px;
	height: 48px;
	.delete-icon {
		visibility: hidden;
		display: block;
		position: relative;
		margin-top: -33px;
		margin-left: 30px;
		color: red;
		cursor: pointer;
		&:hover {
			color: #000;
		}
	}
}

.user-message {
	position: absolute;
	top: 10px;
	right: 30px;
}