#settings-index {
	.nav-tabs {
		li {
			a {
				color: #fff;
			}
		}
	}
	.pmd-tab-active-bar {
		background-color: rgba(255,255,255,.5);
	}
	.tab-pane {
	}
	.tab-content {
		button {
			margin-top: 20px;
		}
		.domain_type {
			margin-top: 26px;
		}
	}
}