/* Bootstrap datetimepicker */
@import "modules/bootstrap-datetimepicker";

/* Propeller datetimepicker */
@import "modules/pmd-datetimepicker";

.pmd-textfield input.form-control {
	height: 36px !important;
}

.pmd-switch {
	label.pmd-switch-with-text {
		margin: 1em;
		.pmd-switch-label {
			display: inline-block;
			margin-right: 1em;
			~ span {
				vertical-align: middle;
			}
		}
	}
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	max-width: 100%;
	font-size: 18px;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	margin: -10px 0 20px 0;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
	/* pointer-events: none; */
	/* in case of FastClick lib use */
}

.inputfile + label svg {
	width: 1em;
	height: 1em;
	vertical-align: middle;
	fill: currentColor;
	margin-top: -0.25em;
	/* 4px */
	margin-right: 0.25em;
	/* 4px */
}
.inputfile + label {
	color: inherit;
}

.inputfile:focus + label,
.inputfile.has-focus + label,
.inputfile + label:hover {
	opacity: .75;
}

.table-icon {
	width: 32px;
	height: 32px;
	margin-right: 5px;
	&.small {
		width: 24px;
		height: 24px;
	}
}
.table-icon-generated {
	display: inline-block;
	width: 32px;
	height: 32px;
	margin-right: 5px;
	font-size: 12px;
	line-height: 32px;
	color: #fff;
	&.small {
		width: 24px;
		height: 24px;
		line-height: 24px;
	}
}
