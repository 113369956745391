.main-header {
	.sidebar-toggle {
		text-decoration: none;
	}
	.nav {
		.search {
			margin: 14px 5px 0 0;
			cursor: pointer;
		}
		.form-group {
			float: left;
			margin: 0 5px 0 0;
			&.closed {
				visibility: hidden;
			}
			&.open {
				visibility: visible;
				input {
					animation: openSearch .5s forwards;
				}
			}
			input {
				width: 0;
				height: auto;
				padding: 5px 10px;
				margin-top: 8px;
			}
		}
	}
	.search-results {
		visibility: hidden;
		position: absolute;
		top: 52px;
		right: 130px;
		width: 25vw;
		min-width: 300px;
		background-color: #fff;
		border: 1px solid #d3e0e9;
		@media (max-width: 768px){
			right: 10px;
		}
		.list-group-item {
			border: none;
			line-height: 24px;
			&:not(:first-child){
				border-top: 1px solid #d3e0e9;
			}
			i {
				float: left;
				width: auto;
				margin-right: 5px;
			}
			ul {
				margin: 0;
				padding: 10px 0 0 0;
				width: 100%;
				list-style: none;
				max-height: 180px;
				overflow-y: auto;
				overflow-x: hidden;
				&::-webkit-scrollbar-track {
					box-shadow: inset 0 1px 3px rgba(0,0,0,.12), inset 0 1px 2px rgba(0,0,0,.24);
					border-radius: 10px;
					background-color: #ecf0f5;
				}
				&::-webkit-scrollbar {
					width: 10px;
					background-color: #ecf0f5;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					box-shadow: inset 0 1px 3px rgba(0,0,0,.12), inset 0 1px 2px rgba(0,0,0,.24);
					background-color: #4285f4;
				}
				li {
					padding: 5px 10px;
					line-height: 18px;
					cursor: pointer;
					&.hide-results {
						display: none;
					}
					&:nth-child(even){
						background-color: #f7f7f7;
					}
					&:hover {
						background-color: #4285f4;
						a {
							color: #fff;
						}
					}
					a {
						color: #333;
					}
					&.show-more-results {
						background-color: rgba(0, 0, 0, 0.15);;
						color: #000;
						&:hover {
							background-color: rgba(0,0,0,.05);
							i {
								transform: rotate(90deg);
							}
						}
					}
				}
			}
		}
	}
}

@keyframes openSearch {
	0% {
		width: 0;
	}
	100% {
		width: 25vw;
		min-width: 150px;
	}
}