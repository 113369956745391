#clients-index {
	.custom-rate-wrapper:not(.active) {
		opacity: 0;
		pointer-events: none;
	}
}

.edit-client {
	position: absolute;
	right: 20px;
	bottom: 20px;
}

.client-actions {
	position: absolute;
	right: 20px;
	bottom: 30px;
}

.restore-client {
	position: absolute;
	top: -5px;
}