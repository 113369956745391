#referrals-index {
	.custom-referral-field {
		display: none;
	}
	.add-payment-button {
		display: inline-block;
	}
	.referral-type-field {
	 display: inline-block; margin-left: 20px;
	}
	.form-group {
		.input-icon-dollar {
			&:before {
				display: none;
				position: absolute;
				content: '$';
				color: rgba(0,0,0,.4);
				left: 0;
				margin: 31px 5px 0 0;
			}
		}
		.input-icon-dollar-pay-amount:before {
			margin-left: -12px;
		}
	}
	.pmd-textfield-floating-label-active {
		.input-icon-dollar {
			&:before {
				display: inline-block !important;
			}
		}
	}
	.edit-client-button {
		margin-top: -20px !important;
	}
	.nav-tabs {
		li {
			a {
				color: #fff;
			}
		}
	}
	.pmd-tab-active-bar {
		background-color: rgba(255,255,255,.5);
	}
	.store-referral {
		margin-bottom: 25px;
		border-bottom: 2px solid rgba(0,0,0,.25);
	}
}

.custom-rate-wrapper {
	display: none;
	&.active {
		display: block;
	}
}

.referral-delete {
	position: absolute !important;
	margin-top: -50px;
	right: 20px;
}

.spinner-container {
	display: none;
	float: left;
	margin-top: 5px;
	margin-left: 25px;
}

.spinner {
	width: 50px;
	height: 25px;
	text-align: center;
	font-size: 10px;
}

.spinner > div {
	height: 100%;
	width: 6px;
	display: inline-block;

	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .rect3 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
	20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}  20% {
		   transform: scaleY(1.0);
		   -webkit-transform: scaleY(1.0);
	   }
}