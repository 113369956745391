body {
	font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.list-group-item {
	border-radius: 0 !important;
	border: 0;
	&:not(:first-child) {
		border-top: 1px solid #d3e0e9;
	}
	&:not(:last-child) {
		border-bottom: 1px solid #d3e0e9;
	}
}

.sublinks .list-group-item:first-child {
	border-top: 2px solid #d3e0e9;
}

.panel {
	border: 0;
}

.pmd-card-title.with-border {
	padding-bottom: 16px;
	border-bottom: 1px solid #f4f4f4
}

.tooltip {
	font-size: 14px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a.list-group-item:focus,
button.list-group-item:focus {
	background-color: #fff;
	&:hover {
		background-color: #f5f5f5;
	}
}