.sidebar-menu {
	a {
		text-decoration: none !important;
	}
}

.material-icons {
	width: 20px;
	margin-right: 3px;
	margin-left: -1px;
	vertical-align: text-top;
}

.user-icon {
	color: #fff;
	line-height: 48px;
}