.preferred_method_header {
	margin-top: 25px;
}

.payment_method_primary_group {
	text-align: center;
	label {
		margin-top: 25px;
	}
}

.user-icon {
	float: left;
	margin-right: 20px;
}

@media only screen and (max-width: 992px){
	.preferred_method_header {
		display: none;
	}
	.payment_method_primary_group {
		text-align: left;
		label {
			margin-top: 0;
			&:after {
				content: 'Preferred Method';
			}
		}
	}
}