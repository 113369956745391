#companies-show {
	.contact-info-table {
		width: 100%;
		td {
			padding: 1em .5em;
			&:first-child {
				width: 3em;
				text-align: center;
			}
			&:last-child {
				text-align: left;
			}
		}
	}
}