.color-font-theme-skin-yellow,
.color-font-theme-skin-yellow-light {
	color: $theme-yellow;
}

.color-font-theme-skin-blue,
.color-font-theme-skin-blue-light {
	color: $theme-blue;
}

.color-font-theme-skin-purple,
.color-font-theme-skin-purple-light {
	color: $theme-purple;
}

.color-font-theme-skin-red,
.color-font-theme-skin-red-light {
	color: $theme-red;
}

.color-font-theme-skin-green,
.color-font-theme-skin-green-light {
	color: $theme-green;
}

body.skin-yellow-light,
body.skin-blue-light,
body.skin-purple-light,
body.skin-red-light,
body.skin-green-light {
	.material-icons.md-light {
		color: #000;
	}
}

body.skin-yellow,
body.skin-blue,
body.skin-purple,
body.skin-red,
body.skin-green {
	.list-group-item[aria-expanded="true"] {
		background: #424242;
		color: #fff;
		&:hover {
			background: #424242;
		}
	}
}


body.skin-yellow-light,
body.skin-blue-light,
body.skin-purple-light,
body.skin-red-light,
body.skin-green-light {
	.list-group-item[aria-expanded="true"] {
		background: #e0e0e0;
		color: #000;
		&:hover {
			background: #e0e0e0;
		}
	}
}

.color-bg-theme-skin-yellow,
.color-bg-theme-skin-yellow-light {
	background-color: $theme-yellow;
}

.color-bg-theme-skin-blue,
.color-bg-theme-skin-blue-light {
	background-color: $theme-blue;
}

.color-bg-theme-skin-purple,
.color-bg-theme-skin-purple-light {
	background-color: $theme-purple;
}

.color-bg-theme-skin-red,
.color-bg-theme-skin-red-light {
	background-color: $theme-red;
}

.color-bg-theme-skin-green,
.color-bg-theme-skin-green-light {
	background-color: $theme-green;
}